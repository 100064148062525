import styled from "@emotion/styled"

export const ContactFormWrapper = styled.article`
  grid-column: center-start / center-end;
  margin: 3.2rem auto 8rem;
  max-width: 110rem;
  padding-top: 4.8rem;
  position: relative;
  width: 100%;

  display: flex;
  align-items: flex-start;
  gap: 4rem;
  justify-content: center;
  flex-direction: row-reverse;
  flex-wrap: wrap;

  header {
    text-align: left;
    margin-bottom: 2rem;
    width: min(64%, 82%);
  }

  h2 {
    font-size: 1.65em;
  }

  @media screen and (max-width: 70em) {
    flex-direction: column;
    align-content: center;
  }
`

export const ContactInfo = styled.aside`
  flex: 0 100000 25rem;
  position: sticky;
  top: 14.8rem;
  max-height: calc(-14.8rem + 100vh);
  overflow: auto;
  padding-block-end: 1.6rem;
  margin-inline-start: auto;
  margin-block-start: 0.4rem;
  margin-block-start: 2rem;

  &::before {
    content: "";
    width: 100%;
    height: 4px;
    position: absolute;
    background-color: var(--grey-4);
  }

  address {
    display: flex;
    gap: 4rem;
    padding-block-start: 2rem;
    flex-direction: column;
    font-size: 1.8rem;

    @media screen and (max-width: 70em) {
      flex-direction: initial;
      flex-wrap: wrap;
    }
  }

  @media screen and (max-width: 70em) {
    position: initial;
    max-height: 100%;
    margin-inline-start: 0;
    overflow: initial;
  }
`

export const IconWrapper = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  border-radius: 100px;
  background-color: var(--grey-4);
`

export const AddressText = styled.div`
  display: flex;
  gap: 1.5rem;
  align-items: center;
`

export const ContactForm = styled.div`
  flex: 1 1 68.6rem;
  max-width: min(680.6rem, 100%);
  padding-inline: 2rem;

  input:placeholder-shown + label {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-4.48rem);
  }
`
