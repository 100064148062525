import React, { useState } from "react"
import { graphql, useStaticQuery, navigate } from "gatsby"
import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image"
import {
  FaPhoneVolume,
  FaMapMarkerAlt,
  FaEnvelopeOpenText,
} from "react-icons/fa"

import Seo from "../components/seo"
import Breadcrumb from "../components/Breadcrumb"
import { CategoryMain } from "../styles/Layout"
import {
  FeaturedImage,
  HeroContent,
} from "../components/Category/CategoryElements"
import {
  ContactInfo,
  ContactFormWrapper,
  IconWrapper,
  AddressText,
} from "../components/Contact/ContactElements"
import {
  FormControlGroup,
  InputLabel,
  TextInput,
  Textarea,
  SubmitBtn,
  Form,
} from "../components/Forms/FormElements"
import Newsletter from "../components/Newsletter"

const getContactPage = graphql`
  query ContactPageQuery {
    contentfulContactPage {
      title
      slug
      description
      featuredImage {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          quality: 80
          aspectRatio: 1.777777
        )
      }
      companyInfo {
        phoneNumber
        email
        state
        country
        icon {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      socialMedia {
        contentful_id
        title
        url
      }
    }
  }
`

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const ContactPage = () => {
  const data = useStaticQuery(getContactPage)
  const {
    title,
    slug,
    seoDescription,
    description,
    featuredImage,
    companyInfo,
  } = data.contentfulContactPage

  const featuredImg = getImage(featuredImage)
  const featuredImgSrc = getSrc(featuredImage)

  const [visitor, setVisitor] = useState({
    name: "",
    email: "",
    message: "",
    messageDate: "",
    messageTme: "",
  })
  const [visitors, setVisitors] = useState([])

  const handleChange = e => {
    const name = e.target.name
    const value = e.target.value
    setVisitor({ ...visitor, [name]: value })
  }
  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    if (visitor.name && visitor.email && visitor.message) {
      const newVisitor = {
        ...visitor,
        messageDate: new Date().toLocaleDateString(),
        messageTme: new Date().toLocaleTimeString(),
      }

      setVisitors([...visitors, newVisitor])

      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": form.getAttribute("name"),
          ...newVisitor,
        }),
      })
        .then(() => navigate(form.getAttribute("action")))
        .catch(error => alert(error))

      setVisitor({
        name: "",
        email: "",
        message: "",
        messageDate: "",
        messageTme: "",
      })
    }
  }

  const regex = /\D+/gm
  const num = companyInfo.phoneNumber
  const formattedPhoneNumber = num.replace(regex, "")

  return (
    <>
      <Seo
        title={title}
        description={seoDescription}
        image={featuredImgSrc}
        imageAlt={featuredImage?.description || title}
      />
      <CategoryMain id="main-content">
        <FeaturedImage>
          <GatsbyImage
            image={featuredImg}
            alt={featuredImage?.description || title}
            style={{
              height: "59vh",
              gridColumn: "1/-1",
              gridRow: "1/-1",
            }}
          />
          <Breadcrumb categoryTitle={title} categoryLink={`${slug}/`} />
          <HeroContent>
            <h1 className="bg-grad">{title}</h1>
            <div>{description}</div>
          </HeroContent>
        </FeaturedImage>

        <ContactFormWrapper>
          <Form
            id="websiteContact"
            name="websiteContact"
            method="POST"
            // action="/thanks/"
            action="/contact/"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={handleSubmit}
          >
            <header>
              <h2>Contact Form</h2>
              <p>
                You may contact us using the form below or by calling our phone
                number. We <strong>reply</strong> to all messages.
              </p>
            </header>

            <input type="hidden" name="form-name" value="websiteContact" />
            <FormControlGroup>
              <TextInput
                type="text"
                id="name"
                name="name"
                value={visitor.name}
                onChange={handleChange}
                placeholder="Name"
                required
              />
              <InputLabel htmlFor="name">Name: </InputLabel>
            </FormControlGroup>
            <FormControlGroup>
              <TextInput
                type="email"
                id="email"
                name="email"
                value={visitor.email}
                onChange={handleChange}
                placeholder="Email"
                required
              />
              <InputLabel htmlFor="email">Email: </InputLabel>
            </FormControlGroup>

            <FormControlGroup>
              <Textarea
                id="message"
                name="message"
                value={visitor.message}
                onChange={handleChange}
                cols={30}
                rows={10}
                required
                placeholder="Message"
              ></Textarea>
              <InputLabel htmlFor="Message">Message:</InputLabel>
            </FormControlGroup>

            <SubmitBtn type="submit">Submit</SubmitBtn>
          </Form>

          <ContactInfo>
            <address>
              <AddressText>
                <IconWrapper>
                  <FaEnvelopeOpenText />
                </IconWrapper>{" "}
                <a href="mail:ceiimages.com@gmail.com">{companyInfo.email}</a>
              </AddressText>

              <AddressText>
                <IconWrapper>
                  <FaPhoneVolume />
                </IconWrapper>{" "}
                <a href={`tel:+1${formattedPhoneNumber}`}>
                  {companyInfo.phoneNumber}
                </a>
              </AddressText>
              <AddressText>
                <IconWrapper>
                  <FaMapMarkerAlt />
                </IconWrapper>{" "}
                {companyInfo.state}, {companyInfo.country}
              </AddressText>
            </address>
          </ContactInfo>
        </ContactFormWrapper>

        <Newsletter />
      </CategoryMain>
    </>
  )
}

export default ContactPage
